import { useStoreState } from "pullstate";

import { UIStore } from "@/store/UIStore";

export const useDataLayer = () => {
  const { userID } = useStoreState(UIStore, (store) => store);

  const pushToDataLayer = (event: string, data?: any) => {
    window?.dataLayer?.push({ event: event, uid: userID, ...data });
  };

  return { pushToDataLayer };
};
