import { ComponentProps } from "@/helpers/componentProps";

export enum ModalSize {
  Small = "small",
  Large = "large",
}

export enum ModalVersion {
  first = "first",
  second = "second",
}

export interface BaseModalInterface extends ComponentProps {
  onClose: (props?: never) => void;
  callback?: (props?: never) => void;
  isOpen: boolean;
}

export interface ModalInterface extends BaseModalInterface {
  size?: ModalSize;
  variant?: "primary" | "secondary";
  version?: ModalVersion;
  className?: string;
  shouldCloseOnOverlayClick?: boolean;
}

export interface ActionsModalInterface extends BaseModalInterface {
  confirmActionHandler: (props: any) => void;
  confirmActionLabel: string;
  cancelActionLabel: string;
}

export interface ShareModalInterface extends BaseModalInterface {
  artwork?: string;
  title?: string;
  subtitle?: string;
  link?: string;
  isStoriesShareAvailable?: boolean;
}
