import React from "react";
import classnames from "classnames";
import Modal from "react-modal";

import { ModalInterface, ModalSize, ModalVersion } from "../Modal.interface";

import styles from "./BaseModal.module.scss";

// Workaround for Modals in Storybook
process.env.NODE_ENV === "development"
  ? Modal.setAppElement("body")
  : Modal.setAppElement("#__next");

export const BaseModal: React.FC<ModalInterface> = ({
  children,
  onClose,
  isOpen,
  size = ModalSize.Small,
  variant = "primary",
  version = ModalVersion.first,
  shouldCloseOnOverlayClick = true,
  className,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    ariaHideApp={false}
    shouldCloseOnEsc
    autoHeight
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    className={classnames(
      styles.modal,
      styles[version],
      styles[variant],
      styles[size],
      className
    )}
    overlayClassName={classnames(styles.overlay, styles[version])}
    bodyOpenClassName={styles.isModalOpen}
  >
    {children}
  </Modal>
);

export default BaseModal;
